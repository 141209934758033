module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"jeangelique","short_name":"jeangelique","start_url":"/","background_color":"#ff6f61","theme_color":"#ff6f61","display":"minimal-ui","icon":"src/images/jeangelique-save-the-date.gif"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
