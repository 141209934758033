// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-souvenirs-template-js": () => import("./../src/templates/souvenirsTemplate.js" /* webpackChunkName: "component---src-templates-souvenirs-template-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-disclaimer-js": () => import("./../src/pages/disclaimer.js" /* webpackChunkName: "component---src-pages-disclaimer-js" */),
  "component---src-pages-family-js": () => import("./../src/pages/family.js" /* webpackChunkName: "component---src-pages-family-js" */),
  "component---src-pages-friends-js": () => import("./../src/pages/friends.js" /* webpackChunkName: "component---src-pages-friends-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-paris-js": () => import("./../src/pages/paris.js" /* webpackChunkName: "component---src-pages-paris-js" */),
  "component---src-pages-paris-oui-js": () => import("./../src/pages/paris/oui.js" /* webpackChunkName: "component---src-pages-paris-oui-js" */),
  "component---src-pages-party-js": () => import("./../src/pages/party.js" /* webpackChunkName: "component---src-pages-party-js" */),
  "component---src-pages-save-the-date-js": () => import("./../src/pages/save-the-date.js" /* webpackChunkName: "component---src-pages-save-the-date-js" */),
  "component---src-pages-stuttgart-js": () => import("./../src/pages/stuttgart.js" /* webpackChunkName: "component---src-pages-stuttgart-js" */),
  "component---src-pages-surprise-surprise-js": () => import("./../src/pages/surprise-surprise.js" /* webpackChunkName: "component---src-pages-surprise-surprise-js" */)
}

